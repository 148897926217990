<template>
    <div
        class="mt40 pl16 pr16 md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
    >
        <div class="one-row mt20">
            <span class="md-headline">{{ $t('settings.pageTitle') }}</span>
        </div>

        <div class="settings-notifications">
            <h3>{{ $t('settings.notifications') }}</h3>
            <ul class="list">
                <div class="subtitle">
                    {{ $t('settings.sendSMSNotifications') }}
                    <span class="pointer">
                        <img
                            @click="tooltipActive = !tooltipActive"
                            src="@assets/images/icon-information.png"
                            alt="info"
                        />
                        <md-tooltip
                            md-direction="bottom"
                            :md-active.sync="tooltipActive"
                        >
                            <span
                                >{{ $t('settings.smsCostMessage') }} 0.2
                                {{ $t('global.currency') }} / 2
                                {{ $t('header.shortcutPoints') }}</span
                            >
                        </md-tooltip>
                    </span>
                </div>
                <md-switch v-model="dontSendSms" @change="updateSettings" />
            </ul>
            <ul class="list">
                <div class="subtitle">
                    {{ $t('settings.sendEmailNotifications') }}
                </div>
                <md-switch v-model="dontSendEmail" @change="updateSettings" />
            </ul>
            <ul class="list">
                <div class="subtitle">
                    {{ $t('settings.lockScreenNotifications') }}
                </div>
                <md-switch v-model="dontSendNotify" @change="updateSettings" />
            </ul>
            <ul class="list">
                <div class="subtitle">
                    {{ $t('settings.emailWithPassengerList') }}
                </div>
                <md-switch v-model="dontSendBulk" @change="updateSettings" />
            </ul>
            <ul class="list">
                <div class="subtitle">
                    {{ $t('settings.passengerOutOfRegion') }}
                </div>
                <md-switch
                    v-model="passengerOutOfRegion"
                    @change="updateSettings"
                />
            </ul>
            <ul class="list">
                <div class="subtitle">
                    {{ $t('settings.passengerCall') }}
                </div>
                <md-switch v-model="passengerCall" @change="updateSettings" />
            </ul>
            <ul class="list">
                <div class="subtitle">
                    {{ $t('settings.passengerDeposit') }} {{ amount }}
                    {{ $t('global.currency') }}
                </div>
                <md-switch
                    v-model="passengerDeposit"
                    @change="updateSettings"
                />
            </ul>
        </div>
        <div class="settings-notifications mt40">
            <div class="one-row">
                <h3>{{ $t('settings.commission') }}</h3>
                <div class="pt16">
                    <div
                        class="right"
                        style="padding-bottom: 0px;font-weight: 400;padding-left: 10px;color: #212121b0;"
                    >
                        {{ amount }} {{ $t('global.currency') }} /
                        {{ amount * 10 }}{{ $t('settings.shortcutPoints') }}
                    </div>
                    <small class="right" style="color: #212121b0;"
                        >{{ $t('settings.weChargeFee') }} 50%</small
                    >
                </div>
            </div>
            <vue-material-slider
                :min="5"
                :max="30"
                v-model="amount"
                id="input-slider"
            />
        </div>

        <div class="settings-notifications mt40 mb40">
            <h3 style="mt40">
                {{ $t('settings.passwordChange') }}
            </h3>
            <form>
                <label
                    class="pure-material-textfield-outlined"
                    :class="{
                        error:
                            ($v.passwordForm.newPassword.$invalid &&
                                !$v.passwordForm.newPassword.minLength) ||
                            notSame
                    }"
                >
                    <input
                        placeholder=" "
                        type="password"
                        v-model="passwordForm.newPassword"
                    />
                    <span>{{ $t('settings.newPassword') }}</span>
                </label>

                <label
                    class="pure-material-textfield-outlined"
                    :class="{
                        error:
                            ($v.passwordForm.confirmPassword.$invalid &&
                                !$v.passwordForm.confirmPassword.minLength) ||
                            notSame
                    }"
                >
                    <input
                        placeholder=" "
                        type="password"
                        v-model="passwordForm.confirmPassword"
                    />
                    <span>{{ $t('settings.repeatPassword') }}</span>
                </label>
                <div class="right mt16">
                    <md-button
                        @click="updatePassword"
                        class="default-button btn-sm-blue"
                        >{{ $t('settings.change') }}</md-button
                    >
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import Route from '../components/Route/main';
import { mapActions, mapGetters } from 'vuex';
import areaCodes from '@assets/areaCodes';
import { required, minLength } from 'vuelidate/lib/validators';

export default {
    components: {
        Route
    },

    validations: {
        passwordForm: {
            newPassword: {
                required,
                minLength: minLength(8)
            },
            confirmPassword: {
                required,
                minLength: minLength(8)
            }
        }
    },

    watch: {
        'passwordForm.newPassword'() {
            this.notSame = false;
        },

        'passwordForm.confirmPassword'() {
            this.notSame = false;
        }
    },

    data() {
        return {
            tooltipActive: false,
            allCountries: areaCodes,
            amount: '',
            phoneNumber: '',

            dontSendSms: false,
            dontSendEmail: false,
            dontSendNotify: false,
            dontSendBulk: false,
            passengerOutOfRegion: false,
            passengerCall: false,
            passengerDeposit: false,

            notSame: false,
            passwordForm: {
                newPassword: '',
                confirmPassword: ''
            }
        };
    },

    computed: {
        ...mapGetters({
            currentUser: 'auth/currentUser'
        })
    },

    methods: {
        ...mapActions({
            getCarrier: 'auth/validate',
            getNoticonf: 'settings/getNoticonf',
            changeNoticonf: 'settings/changeNoticonf',
            widgetCommission: 'settings/widgetCommission',
            accountUpdate: 'settings/accountUpdate'
        }),

        formatData(response) {
            const {
                dont_send_sms,
                dont_send_mail,
                dont_send_notify,
                dont_send_bulk,
                passenger_out_of_region,
                passenger_call,
                passenger_deposit
            } = response;

            this.dontSendSms = !dont_send_sms;
            this.dontSendEmail = !dont_send_mail;
            this.dontSendNotify = !dont_send_notify;
            this.dontSendBulk = !dont_send_bulk;
            this.passengerOutOfRegion = passenger_out_of_region;
            this.passengerCall = passenger_call;
            this.passengerDeposit = passenger_deposit;
        },

        async getSettings() {
            const response = await this.getNoticonf();
            this.formatData(response);
        },

        async updateSettings() {
            const data = JSON.stringify({
                dont_send_sms: !this.dontSendSms,
                dont_send_mail: !this.dontSendEmail,
                dont_send_notify: !this.dontSendNotify,
                dont_send_bulk: !this.dontSendBulk,
                passenger_out_of_region: this.passengerOutOfRegion,
                passenger_call: this.passengerCall,
                passenger_deposit: this.passengerDeposit
            });

            await this.changeNoticonf(data);

            this.$toast.success(this.$t('settings.changesSaved'));
        },

        async changeCommission() {
            const { amount } = this;
            const response = await this.widgetCommission(amount);
            if (response) {
                this.$toast.success(this.$t('settings.changesSaved'));
            }
        },

        async updatePassword() {
            const {
                newPassword: password,
                confirmPassword
            } = this.passwordForm;

            if (this.$v.passwordForm.$invalid) {
                this.$toast.error('Uzupełnij formularz ');
                this.notSame = true;

                return false;
            }

            const params = {
                password,
                currentUser: this.currentUser
            };

            if (password !== confirmPassword) {
                this.$toast.error(this.$t('settings.passwordsDoNotMatch'));
                this.notSame = true;
            } else {
                const response = await this.accountUpdate(params);

                if (response) {
                    this.$toast.success(this.$t('settings.changesSaved'));
                }
            }
        }
    },

    mounted() {
        this.getCarrier();
        this.getSettings();
        this.phoneNumber = this.currentUser.phone;
        this.amount = this.currentUser.commission_user;

        const slider = document.getElementById('input-slider');
        slider.addEventListener('mouseup', this.changeCommission);
        slider.addEventListener('touchend', this.changeCommission, false);
    }
};
</script>
