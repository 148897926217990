const areaCodes = [
    {
        name: 'Poland',
        iso2: 'PL',
        dialCode: '48'
    },
    {
        name: 'United Kingdom',
        iso2: 'GB',
        dialCode: '44'
    },
    {
        name: 'Ukraine',
        iso2: 'UA',
        dialCode: '380'
    },
    {
        name: 'Switzerland',
        iso2: 'CH',
        dialCode: '41'
    },
    {
        name: 'Sweden',
        iso2: 'SE',
        dialCode: '46'
    },
    {
        name: 'Spain',
        iso2: 'ES',
        dialCode: '34'
    },
    {
        name: 'Slovenia',
        iso2: 'SI',
        dialCode: '386'
    },
    {
        name: 'Slovakia',
        iso2: 'SK',
        dialCode: '421'
    },
    {
        name: 'Serbia',
        iso2: 'RS',
        dialCode: '381'
    },
    {
        name: 'San Marino',
        iso2: 'SM',
        dialCode: '378'
    },
    {
        name: 'Romania',
        iso2: 'RO',
        dialCode: '40'
    },
    {
        name: 'Netherlands',
        iso2: 'NL',
        dialCode: '31'
    },
    {
        name: 'Montenegro',
        iso2: 'ME',
        dialCode: '382'
    },
    {
        name: 'Monaco',
        iso2: 'MC',
        dialCode: '377'
    },
    {
        name: 'Moldova',
        iso2: 'MD',
        dialCode: '373'
    },
    {
        name: 'Russia',
        iso2: 'RU',
        dialCode: '7'
    },
    {
        name: 'Portugal',
        iso2: 'PT',
        dialCode: '351'
    },
    {
        name: 'Norway',
        iso2: 'NO',
        dialCode: '47'
    },
    {
        name: 'Malta',
        iso2: 'MT',
        dialCode: '356'
    },
    {
        name: 'Macedonia',
        iso2: 'MK',
        dialCode: '389'
    },
    {
        name: 'Luxembourg',
        iso2: 'LU',
        dialCode: '352'
    },
    {
        name: 'Lithuania',
        iso2: 'LT',
        dialCode: '370'
    },
    {
        name: 'Liechtenstein',
        iso2: 'LI',
        dialCode: '423'
    },
    {
        name: 'Latvia',
        iso2: 'LV',
        dialCode: '371'
    },
    {
        name: 'Italy',
        iso2: 'IT',
        dialCode: '39'
    },
    {
        name: 'Ireland',
        iso2: 'IE',
        dialCode: '353'
    },
    {
        name: 'Iceland',
        iso2: 'IS',
        dialCode: '354'
    },
    {
        name: 'Hungary',
        iso2: 'HU',
        dialCode: '36'
    },
    {
        name: 'Greece',
        iso2: 'GR',
        dialCode: '30'
    },
    {
        name: 'Gibraltar',
        iso2: 'GI',
        dialCode: '350'
    },
    {
        name: 'Germany',
        iso2: 'DE',
        dialCode: '49'
    },
    {
        name: 'France',
        iso2: 'FR',
        dialCode: '33'
    },
    {
        name: 'Finland',
        iso2: 'FI',
        dialCode: '358'
    },
    {
        name: 'Faroe Islands',
        iso2: 'FO',
        dialCode: '298'
    },
    {
        name: 'Estonia',
        iso2: 'EE',
        dialCode: '372'
    },
    {
        name: 'Denmark',
        iso2: 'DK',
        dialCode: '45'
    },
    {
        name: 'Czech Republic',
        iso2: 'CZ',
        dialCode: '420'
    },
    {
        name: 'Croatia',
        iso2: 'HR',
        dialCode: '385'
    },
    {
        name: 'Bulgaria',
        iso2: 'BG',
        dialCode: '359'
    },
    {
        name: 'Bosnia and Herzegovina',
        iso2: 'BA',
        dialCode: '387'
    },
    {
        name: 'Belgium',
        iso2: 'BE',
        dialCode: '32'
    },
    {
        name: 'Belarus',
        iso2: 'BY',
        dialCode: '375'
    },
    {
        name: 'Austria',
        iso2: 'AT',
        dialCode: '43'
    },
    {
        name: 'Andorra',
        iso2: 'AD',
        dialCode: '376'
    },
    {
        name: 'Albania',
        iso2: 'AL',
        dialCode: '355'
    }
];

export default areaCodes;
